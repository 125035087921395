import './App.css';
import logo from "./Image/logo.png";
import { FaFacebook,FaInstagram } from "react-icons/fa";
function App() {
  return (
    <div className="App">
      
        <div className='image'>
            <img src={logo} className='logo' alt='logo'/>
        </div> 
        <p className='msg'>Website is under construction , till then you can follow our socials</p>
        <div className='urllinks'>
            <a href='https://www.facebook.com/profile.php?id=61554449403676'><FaFacebook className='icons'/></a>
            <a href='https://www.instagram.com/roshimmigration/'><FaInstagram  className='icons'/></a>
        </div>
      
    </div>
  );
}

export default App;
